<template>
    <div class="item" :class="{ item__selectable: listItems.length }" @click="selectCustomList">
        <div>
            <div class="item__title">{{ list.name }}</div>
            <div class="item__subtitle">
                <span>Created {{ list.created | dateFilter('date, time') }}</span>
                <span v-if="listItems.length">, contains {{ listItems.length }} {{ list.type }}</span>
            </div>
        </div>
        <div class="item__buttons">
            <mf-button size="x-small" class="item__button" @click.stop="addItem">
                <mf-icon slot="icon" icon-name="icon-plus" width="12" height="12" />
                Add {{ list.type }}
            </mf-button>
            <mf-button size="x-small" class="item__button" @click.stop="removeItem">Remove</mf-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CustomListItem',
        props: {
            list: {
                type: Object,
                required: true,
            },
        },
        computed: {
            listItems() {
                return this.list[`${this.list.type}s`];
            },
        },
        methods: {
            selectCustomList() {
                this.listItems.length && this.$emit('selectList', this.list);
            },
            addItem() {
                this.$emit('addItem', this.list);
            },
            removeItem() {
                this.$store.dispatch('removeCustomList', this.list);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .item {
        display: flex;
        flex-direction: column;

        padding: $space-8 $space-16;
        border: 1px solid $marine-500;
        box-sizing: border-box;
        border-radius: 6px;
        @include media($lg) {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
    }

    .item__selectable {
        cursor: pointer;

        &:hover {
            opacity: 0.7;
        }
    }

    .item__title {
        font-weight: 600;
        font-size: $font-16;
        line-height: $font-24;
        color: $white;
    }

    .item__subtitle {
        font-size: $font-12;
        line-height: $font-20;
        color: $marine-300;
    }

    .item__buttons {
        display: flex;
    }

    .item__button:not(:last-child) {
        margin-right: $space-8;
    }
</style>
