<template>
    <mf-modal small :scrollable="false" v-if="isOpen" @close="close">
        <div slot="header">
            {{ listName }}
        </div>
        <slot />
    </mf-modal>
</template>

<script>
    export default {
        name: 'ShowCustomListItemsModal',
        props: {
            isOpen: {
                type: Boolean,
                required: true,
            },
            listName: {
                type: String,
            },
        },
        methods: {
            close() {
                this.$emit('close');
            },
        },
    };
</script>
