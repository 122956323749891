<template>
    <div class="container">
        <div class="container__header">My Lists</div>
        <div class="container__menu">
            <mf-dropdown class="menu_mobile" :open-from-right="false" with-top-space>
                <div slot="link">
                    <div class="menu__item menu__item_mobile">
                        <div class="menu__icon" :class="iconClass(selectedOption, true)">
                            <img :src="optionImg(selectedOption.image)" :alt="selectedOption.name" />
                        </div>
                        <div class="menu__name">
                            {{ selectedOption.name }}
                        </div>
                    </div>
                </div>
                <div class="mobile-items">
                    <template v-for="(option, index) in displayedOptions">
                        <div class="menu__link" :key="`menu-${option.name}`">
                            <a href="javascript://" class="menu__item" @click="selectOption(option)">
                                <div class="menu__icon" :class="iconClass(option, false)">
                                    <img :src="optionImg(option.image)" :alt="option.name" />
                                </div>
                                <div class="menu__name">
                                    {{ option.name }}
                                </div>
                            </a>
                        </div>
                        <mf-divider v-if="index !== displayedOptions.length - 1" :key="option.name" />
                    </template>
                </div>
            </mf-dropdown>
        </div>
        <div class="container__row">
            <div
                class="container__item container__item_half"
                :class="{ container__item_hidden: selectedOption.name !== 'Vessels' }"
            >
                <my-vessel-lists />
            </div>
            <div
                class="container__item container__item_half"
                :class="{ container__item_hidden: selectedOption.name !== 'Quays' }"
            >
                <my-quay-lists />
            </div>
        </div>
    </div>
</template>

<script>
    import MyVesselLists from '@/components/my-lists-components/MyVesselLists';
    import MyQuayLists from '@/components/my-lists-components/MyQuayLists';
    export default {
        name: 'MyLists',
        components: { MyQuayLists, MyVesselLists },
        computed: {
            displayedOptions() {
                return this.options.filter(option => option.name !== this.selectedOption.name);
            },
        },
        data() {
            return {
                selectedOption: { name: 'Vessels', image: 'portcall', color: 'blue' },
                options: [
                    { name: 'Vessels', image: 'portcall', color: 'blue' },
                    { name: 'Quays', image: 'quay', size: 'small', color: 'green' },
                ],
            };
        },
        methods: {
            selectOption(option) {
                this.selectedOption = option;
            },
            optionImg(imageName) {
                const routeIcons = require.context('@/assets/icons/menu', false, /\.svg$/);
                const routeIcon = imageName.toLowerCase();
                return routeIcons(`./${routeIcon}.svg`);
            },
            iconClass({ color, size }, isActive) {
                return [size && `menu__icon_${size}`, isActive && color && `menu__icon_${color}`];
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/sass/default/layout.scss';

    .container {
        padding-bottom: $space-16;
    }

    .container__item_hidden {
        display: none;
        @include media($lg) {
            display: flex;
        }
    }

    .container__menu {
        display: flex;
        justify-content: center;
    }

    .menu_mobile {
        display: block;
        background: $marine-700;
        border-radius: 6px;
        padding: $space-12 $space-16;
        @include media($lg) {
            display: none;
        }
    }

    .menu__list {
        display: flex;
        margin: 0 -$space-16;
    }

    .menu__link {
        padding: 0 $space-8;
        cursor: pointer;
    }

    .menu__item {
        display: flex;
        align-items: center;
        padding: $space-8;
    }

    .menu__item_mobile {
        padding: 0;
    }

    .menu__icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: $marine-600;
        border-radius: $border-radius-8;
        padding: 5px;
        width: 32px;
        height: 32px;
        @include media($md) {
            width: 40px;
            height: 40px;
        }
    }

    .menu__icon_small {
        padding: 8px;
    }

    .menu__icon_blue {
        background: $blue-500;
    }

    .menu__icon_green {
        background: $green-700;
    }

    .menu__icon_sea {
        background: #0ba59b;
    }

    .menu__icon_orange {
        background: $orange-500;
    }

    .menu__name {
        color: $white;
        font-weight: 600;
        font-size: $font-16;
        line-height: $font-24;
        padding-left: $space-8;
        @include media($lg) {
            padding-left: $space-12;
        }
        @include media($xlg) {
            font-size: $font-18;
        }
    }

    /deep/ {
        .dropdown__container {
            min-width: 160px;
        }
        .dropdown__container_with-top-space {
            top: 72px;
        }
    }
</style>
