<template>
    <mf-box>
        <div class="custom-lists__header" slot="header">
            <div>
                Vessel lists
                <span v-if="customVesselLists.length" class="custom-lists__amount">
                    {{ customVesselLists.length }}
                </span>
            </div>
            <mf-button class="list__button" size="small" @click="toggleCreateModal">
                <mf-icon slot="icon" icon-name="icon-plus" width="12" height="12" />
                Add list
            </mf-button>
        </div>
        <div class="custom-lists__body">
            <div v-if="customVesselLists.length">
                <custom-list-item
                    class="custom-lists__inner-item"
                    v-for="list in customVesselLists"
                    :list="list"
                    :key="list.name"
                    @selectList="handleListSelect"
                    @addItem="handleAddItem"
                />
            </div>
            <div v-else class="custom-lists__empty">
                You haven't created any vessel lists yet.
            </div>
        </div>
        <create-custom-list-modal
            :is-open="isCreateModalShown"
            list-type="vessel"
            :custom-lists="customVesselLists"
            @close="toggleCreateModal"
        />
        <show-custom-list-items-modal
            :is-open="isListModalShown"
            :list-name="selectedListName"
            @close="toggleListModal"
        >
            <small-vessel-preview
                class="custom-lists__inner-item"
                v-for="item in selectedListItems"
                :vessel="item"
                :list="selectedList"
                :key="item.mmsi"
            />
        </show-custom-list-items-modal>
        <add-item-to-custom-list-modal
            :is-open="isAddModalShown"
            :list-name="selectedListName"
            @close="toggleAddModal"
            @addItemToCustomList="handleAddItemToCustomList"
        >
            <mf-input placeholder="Vessel IMO" type="number" v-model="selectedItem"></mf-input>
        </add-item-to-custom-list-modal>
    </mf-box>
</template>

<script>
    import { mapGetters } from 'vuex';
    import CreateCustomListModal from '@/modals/CreateCustomListModal';
    import CustomListItem from '@/components/my-lists-components/CustomListItem';
    import ShowCustomListItemsModal from '@/modals/ShowCustomListItemsModal';
    import SmallVesselPreview from '@/components/my-lists-components/SmallVesselPreview';
    import AddItemToCustomListModal from '@/modals/AddItemToCustomListModal';
    import vesselVR from '@/services/vessel-registry/vesselVR';

    export default {
        name: 'MyVesselLists',
        components: {
            AddItemToCustomListModal,
            SmallVesselPreview,
            ShowCustomListItemsModal,
            CustomListItem,
            CreateCustomListModal,
        },
        computed: {
            ...mapGetters(['customVesselLists']),
            selectedListName() {
                return (this.selectedList && this.selectedList.name) || '';
            },
            selectedListItems() {
                return this.selectedList && this.selectedList[`${this.selectedList.type}s`];
            },
        },
        data() {
            return {
                isCreateModalShown: false,
                isListModalShown: false,
                isAddModalShown: false,
                selectedList: null,
                selectedItem: null,
                possibleVessels: [],
            };
        },
        methods: {
            handleListSelect(list) {
                this.selectedList = list;
                this.toggleListModal();
            },
            handleAddItem(list) {
                this.selectedList = list;
                this.toggleAddModal();
            },
            async handleAddItemToCustomList() {
                const fullVesselImo = 'urn:mrn:stm:vessel:imo:' + this.selectedItem;
                if (this.selectedList && this.selectedItem && !this.isVesselExistInList(fullVesselImo)) {
                    try {
                        const vesselResponse = await vesselVR.show(fullVesselImo);
                        await this.$store.dispatch('addItemToCustomList', {
                            list: this.selectedList,
                            item: vesselResponse.data,
                        });
                        this.selectedItem = null;
                        this.toggleAddModal();
                    } catch {
                        this.$toasted.error('There is no such vessel');
                    }
                } else {
                    this.$toasted.error('Vessel with this name has already been created');
                }
            },
            isVesselExistInList(vesselImo) {
                const vesselsImo = this.selectedList.vessels.map(item => item.imo);
                return vesselsImo.includes(vesselImo);
            },
            toggleCreateModal() {
                this.isCreateModalShown = !this.isCreateModalShown;
            },
            toggleListModal() {
                this.isListModalShown = !this.isListModalShown;
            },
            toggleAddModal() {
                this.isAddModalShown = !this.isAddModalShown;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .custom-lists__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .custom-lists__amount {
        margin-left: $space-8;
        font-weight: normal;
        font-size: $font-22;
        line-height: $font-30;
        color: $marine-300;
    }

    .custom-lists__inner-item:not(:last-child) {
        margin-bottom: $space-8;
    }

    .custom-lists__empty {
        margin-top: $space-50;
        text-align: center;
        font-size: $font-16;
        line-height: $font-20;
        color: $marine-400;
        min-height: 100px;
    }

    .custom-lists__body {
        overflow-y: auto;
        overflow-x: hidden;
        @include scrollbar();
    }

    .vessel {
        display: flex;
        align-items: center;
    }

    .vessel__image {
        width: 50px;
        height: 50px;
        border-radius: 8px;
    }

    .vessel__info {
        margin-left: $space-8;
        display: flex;
        flex-direction: column;

        font-size: $font-14;
        line-height: $font-24;
        color: $white;
    }

    .vessel__value {
        font-weight: 700;
    }
</style>
