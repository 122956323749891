<template>
    <mf-modal small :scrollable="false" v-if="isOpen" @close="close">
        <div slot="header">
            {{ listName }}
        </div>
        <slot />
        <div slot="footer">
            <div class="modal__buttons">
                <mf-button color="blue" @click="addItem">Add</mf-button>
            </div>
        </div>
    </mf-modal>
</template>

<script>
    export default {
        name: 'AddItemToCustomListModal',
        props: {
            isOpen: {
                type: Boolean,
                required: true,
            },
            listName: {
                type: String,
            },
        },
        methods: {
            close() {
                this.$emit('close');
            },
            addItem() {
                this.$emit('addItemToCustomList');
            },
        },
    };
</script>

<style lang="scss" scoped>
    .modal__buttons {
        display: flex;
        justify-content: flex-end;
    }
</style>
