<template>
    <div class="item">
        <div>
            <div class="item__title">{{ quay.name }}</div>
            <div class="item__subtitle">{{ quay.URN }}</div>
        </div>
        <div class="item__buttons">
            <mf-button size="x-small" class="item__button" @click="removeQuay">Remove</mf-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SmallQuayPreview',
        props: {
            quay: {
                type: Object,
                required: true,
            },
            list: {
                type: Object,
                required: true,
            },
        },
        methods: {
            removeQuay() {
                this.$store.dispatch('removeItemFromCustomList', { list: this.list, item: this.quay, itemKey: 'URN' });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .item {
        display: flex;
        flex-direction: column;

        padding: $space-8 $space-16;
        border: 1px solid $marine-500;
        box-sizing: border-box;
        border-radius: 6px;
        @include media($lg) {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
    }

    .item__title {
        font-weight: 600;
        font-size: $font-16;
        line-height: $font-24;
        color: $white;
    }

    .item__subtitle {
        font-size: $font-12;
        line-height: $font-20;
        color: $marine-300;
    }

    .item__buttons {
        display: flex;
    }

    .item__button:not(:last-child) {
        margin-right: $space-8;
    }
</style>
