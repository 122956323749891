<template>
    <mf-modal small :scrollable="false" v-if="isOpen" @close="close">
        <div slot="header">
            Create new list
        </div>
        <div>
            <mf-input placeholder="List name" v-model="name" />
        </div>
        <div slot="footer">
            <div class="modal__buttons">
                <mf-button color="blue" @click="addList">Add</mf-button>
            </div>
        </div>
    </mf-modal>
</template>

<script>
    export default {
        name: 'CreateCustomListModal',
        props: {
            isOpen: {
                type: Boolean,
                required: true,
            },
            listType: {
                type: String,
                required: true,
            },
            customLists: {
                type: Array,
                default() {
                    return [];
                },
            },
        },
        data() {
            return {
                name: '',
            };
        },
        methods: {
            close() {
                this.$emit('close');
            },
            addList() {
                if (!this.customLists.find(list => list.name === this.name)) {
                    const newList = {
                        checked: false,
                        name: this.name,
                        type: this.listType,
                        created: new Date().toISOString(),
                    };
                    newList[`${newList.type}s`] = [];
                    this.$store.dispatch('addCustomList', newList);
                    this.name = '';
                    this.close();
                } else {
                    this.$toasted.error('List with this name has already been created');
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .modal__buttons {
        display: flex;
        justify-content: flex-end;
    }
</style>
