<template>
    <mf-box scrollable>
        <div class="custom-lists__header" slot="header">
            <div>
                Quay lists
                <span v-if="customQuayLists.length" class="custom-lists__amount">
                    {{ customQuayLists.length }}
                </span>
            </div>
            <mf-button class="list__button" size="small" @click="toggleCreateModal">
                <mf-icon slot="icon" icon-name="icon-plus" width="12" height="12" />
                Add list
            </mf-button>
        </div>
        <div class="custom-lists__body">
            <div v-if="customQuayLists.length">
                <custom-list-item
                    class="custom-lists__inner-item"
                    v-for="list in customQuayLists"
                    :list="list"
                    :key="list.name"
                    @selectList="handleListSelect"
                    @addItem="handleAddItem"
                />
            </div>
            <div v-else class="custom-lists__empty">
                You haven't created any quay lists yet.
            </div>
        </div>
        <create-custom-list-modal
            :is-open="isCreateModalShown"
            list-type="quay"
            :custom-lists="customQuayLists"
            @close="toggleCreateModal"
        />
        <show-custom-list-items-modal
            :is-open="isListModalShown"
            :list-name="selectedListName"
            @close="toggleListModal"
        >
            <small-quay-preview
                class="custom-lists__inner-item"
                v-for="item in selectedListItems"
                :quay="item"
                :list="selectedList"
                :key="item.URN"
            />
        </show-custom-list-items-modal>
        <add-item-to-custom-list-modal
            :is-open="isAddModalShown"
            :list-name="selectedListName"
            @close="toggleAddModal"
            @addItemToCustomList="handleAddItemToCustomList"
        >
            <mf-select
                v-model="selectedItem"
                placeholder="Search quay"
                :options="filteredPossibleVessels"
                hide-selected
                label="name"
            >
                <div slot="caret">
                    <mf-icon class="multiselect__select" icon-name="icon-down" />
                </div>
            </mf-select>
        </add-item-to-custom-list-modal>
    </mf-box>
</template>

<script>
    import { mapGetters } from 'vuex';

    import CreateCustomListModal from '@/modals/CreateCustomListModal';
    import CustomListItem from '@/components/my-lists-components/CustomListItem';
    import ShowCustomListItemsModal from '@/modals/ShowCustomListItemsModal';
    import SmallQuayPreview from '@/components/my-lists-components/SmallQuayPreview';
    import AddItemToCustomListModal from '@/modals/AddItemToCustomListModal';

    export default {
        name: 'MyQuayLists',
        components: {
            AddItemToCustomListModal,
            SmallQuayPreview,
            ShowCustomListItemsModal,
            CustomListItem,
            CreateCustomListModal,
        },
        computed: {
            ...mapGetters(['customQuayLists', 'quays']),
            selectedListName() {
                return (this.selectedList && this.selectedList.name) || '';
            },
            selectedListItems() {
                return this.selectedList && this.selectedList[`${this.selectedList.type}s`];
            },
            filteredPossibleVessels() {
                return this.quays && this.quays.filter(quay => !this.selectedListItemsIds.includes(quay.URN));
            },
            selectedListItemsIds() {
                return (this.selectedListItems && this.selectedListItems.map(item => item.URN)) || [];
            },
        },
        mounted() {
            this.$store.dispatch('getQuays');
        },
        data() {
            return {
                isCreateModalShown: false,
                isListModalShown: false,
                isAddModalShown: false,
                selectedList: null,
                selectedItem: null,
            };
        },
        methods: {
            handleListSelect(list) {
                this.selectedList = list;
                this.toggleListModal();
            },
            handleAddItem(list) {
                this.selectedList = list;
                this.toggleAddModal();
            },
            handleAddItemToCustomList() {
                this.$store.dispatch('addItemToCustomList', {
                    list: this.selectedList,
                    item: this.selectedItem,
                });
                this.selectedItem = null;
            },
            toggleCreateModal() {
                this.isCreateModalShown = !this.isCreateModalShown;
            },
            toggleListModal() {
                this.isListModalShown = !this.isListModalShown;
            },
            toggleAddModal() {
                this.isAddModalShown = !this.isAddModalShown;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .custom-lists__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .custom-lists__amount {
        margin-left: $space-8;
        font-weight: normal;
        font-size: $font-22;
        line-height: $font-30;
        color: $marine-300;
    }

    .custom-lists__inner-item:not(:last-child) {
        margin-bottom: $space-8;
    }

    .custom-lists__empty {
        margin-top: $space-50;
        text-align: center;
        font-size: $font-16;
        line-height: $font-20;
        color: $marine-400;
        min-height: 100px;
    }

    .custom-lists__body {
        overflow-y: auto;
        overflow-x: hidden;
        @include scrollbar();
    }
</style>
