<template>
    <div class="item">
        <div class="item__value">
            <div class="item__image" :style="{ backgroundImage: `url(${vessel.photoUrl})` }" />
            <div>
                <div class="item__title">{{ vessel.name }}</div>
                <div class="item__subtitle">
                    <div class="item__info">IMO: {{ vessel.imo }}</div>
                    <div class="item__info">MMSI: {{ vessel.mmsi }}</div>
                    <div class="item__info">Call sign: {{ vessel.callSign }}</div>
                </div>
            </div>
        </div>
        <div class="item__buttons">
            <mf-button size="x-small" class="item__button" @click="removeVessel">Remove</mf-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SmallVesselPreview',
        props: {
            vessel: {
                type: Object,
                required: true,
            },
            list: {
                type: Object,
                required: true,
            },
        },
        methods: {
            removeVessel() {
                this.$store.dispatch('removeItemFromCustomList', {
                    list: this.list,
                    item: this.vessel,
                    itemKey: 'mmsi',
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .item {
        display: flex;
        flex-direction: column;

        padding: $space-8 $space-16;
        border: 1px solid $marine-500;
        box-sizing: border-box;
        border-radius: 6px;
        @include media($lg) {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
    }

    .item__value {
        display: flex;
        align-items: center;
    }

    .item__image {
        height: 50px;
        width: 50px;
        border-radius: 8px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        margin-right: $space-8;
    }

    .item__title {
        font-weight: 600;
        font-size: $font-16;
        line-height: $font-24;
        color: $white;
    }

    .item__subtitle {
        font-size: $font-12;
        line-height: $font-20;
        color: $marine-300;
        display: flex;
        flex-wrap: wrap;
        margin: (-$space-4);
    }

    .item__info {
        padding: $space-4;
    }

    .item__buttons {
        display: flex;
    }

    .item__button:not(:last-child) {
        margin-right: $space-8;
    }
</style>
